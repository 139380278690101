body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin: 40px 8px;
}

.link-area {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin: 16px 8px;
}

.title-area {
    padding-bottom: 20px;
}

header h1 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    padding: 8px;
    text-align: center;
}

header a {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    padding: 8px;
    color: rgb(0, 112, 243);
    text-decoration: none;
}

header a:hover {
    text-decoration: underline;
}

main {
    margin: 5px;
    padding: 0;
}

.main-container {
    display: block;
}

.folders-container {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 100px;
    height: 100%;
    margin-top: 80px;
}

.area {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 50vh;

    /* position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    margin:auto;         */
}

.image-container {
    display: block;
}

.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.top-banner {
    text-align: center;
}

@media all and (min-width: 300px) {
    main {
        margin: 10px;
    }
}

@media all and (min-width: 600px) {
    main {
        margin: 15px;
    }
}

@media all and (min-width: 1200px) {
    main {
        margin: 20px;
    }
}

@media all and (max-width: 600px) {
    .folders-container {
        display: block;
    }
    .image-container {
        margin-bottom: 60px;
    }
    .top-banner img {
        /* display: none; */
        width: 100%;
    }
}


@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.spinner-container {
    position: fixed;
    z-index: 999;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;    
}

.loading-spinner {
    width: 40px;
    height: 40px;
    border: 5px solid #F2C204; /* Light grey */
    border-top: 5px solid #019832; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}